<template>
  <div class="details">
    <!-- <div class="top_Img">
      <img src="../../assets/banner/member.jpg" alt="">
      <div class="tips">成员单位</div>
    </div> -->
    <div v-if="loading" class="spin-loading">
      <a-spin tip="Loading..." size="large">
        <div class="spin-content"></div>
      </a-spin>
    </div>
    <div v-else>
      <div class="detailsMessage">
        <div v-if="!memberstatus">
          <div v-if="IsshowTime" class="title">{{detailsData.meeting_name}}</div>
          <div v-else class="title">{{detailsData.title}}</div>
          <div class="publishtime shareUrl">
            <div>
              发布时间：{{moment(detailsData.pubdate).format('YYYY-MM-DD')}}<span style="margin-left:15px;">阅读量：{{detailsData.hits}}</span>
            </div>
            <div @mouseover="Share" class="share"><a-icon type="share-alt" />微信分享
              <div id="qrcode" class="shareImg"></div>
            </div>
            </div>
          <div class="timeAndaddress" v-if="detailsData.meeting_address">
            <p><span class="bg common">会议时间</span><span class="text common">{{detailsData.meeting_start_time}}~{{detailsData.meeting_end_time}}</span></p>
            <p><span class="bg common">会议地点</span><span class="text common">{{detailsData.meeting_address}}</span></p>
            <p><span class="bg common">主办单位</span><span class="text common">{{detailsData.organizer}}</span></p>
          </div>
        </div>
        <div v-else>
          <div class="title">{{memberData.name}}</div>
          <div class="publishtime">发布时间：{{moment(memberData.pubdate).format('YYYY-MM-DD')}}<span style="margin-left:15px;">阅读量：{{memberData.hits}}</span></div>
        </div>
      <div class="content" v-if="!memberstatus" v-html="detailsData.content" :style="{'border-top': detailsData.title == '' ? '0' : '1px solid #ececec !important;'}">
        {{detailsData.content}}
      </div>
      <div class="content" v-else>
        <div v-html="memberData.content">{{memberData.content}}</div>
      </div>
      <div class="othernews" v-if="detailsData.title">
        <div style="float:left;cursor:pointer;" v-if="lastMessage.id != '-1'" class="last" @click="UpDown(lastMessage.id)">上一篇：{{lastMessage.title}}</div>
        <div style="float:right;cursor:pointer;" v-if="nextMessage.id != '-1'" class="next" @click="UpDown(nextMessage.id)">下一篇：{{nextMessage.title}}</div>
      </div>
      </div>
     </div>
  </div>
</template>

<script>
import merge from 'webpack-merge';
import moment from 'moment';
import QRCode from "qrcodejs2";
export default {
  data(){
    return {
      link:'',
      moment,
      detailsData:{},
      memberData:{},
      memberstatus:false,
      lastMessage:{},
      nextMessage:{},
      IsshowTime:false,

      loading:true,
    }
  },
  components:{QRCode},
  watch:{
    $route(){
      let memberID = this.$route.query.memberID;
      if(memberID){
        this.getMember();
      }else{
        this.getNewsDetails();
        this.getLastAndNext();
      }
      this.link = 'http://m.gupm.net/#' + window.location.href.split('#')[1];
      // this.$nextTick(()=>{
      //   this.isShowOpen();
      //   this.qrcode();
      // })
    }
  },
  methods:{
    // 分享
    Share(){
      this.$nextTick(()=>{
        this.isShowOpen();
        this.qrcode();
      })
    },
    // 清除生成的二维码
    isShowOpen(topId) {
      const codeHtml = document.getElementById("qrcode");
      codeHtml.innerHTML = "";
   },
    qrcode () {
        let that = this;
        let qrcode = new QRCode('qrcode', {
            width: 94,
            height: 94,        // 高度
            text:  that.link,   // 二维码内容
            render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
            // background: '#f0f',   // 背景色
            // foreground: '#ff0'    // 前景色
        })
    },
    // 查看上下篇内容
    UpDown(id){
      this.$router.push({
        query:merge(this.$route.query,{'detailID':id})
      })
    },
    // 查询数据详情
    getNewsDetails(){
      let DetailsId = this.$route.query.detailID;
      this.$get('/v1/news/' + DetailsId).then(res=>{
        let status = false;
        for(let key in res){
          status = true;
        }
        if(status){
          this.detailsData = res;
          this.$root.$emit('changeImg',res.column_id);
          this.memberstatus = false;
        }else{
          this.$router.push('home');
        }
      })
    },
    // 查询成员详情
    getMember(){
      let memberID = this.$route.query.memberID;
      this.$get('/v1/member/' + memberID).then(res=>{
        this.memberData = res.data;
        this.$root.$emit('changeImg',res.column_id);
        this.memberstatus = true;
      }) 
    },
    // 获取上下篇文章
    getLastAndNext(){
      let DetailsId = this.$route.query.detailID;
      this.$get('/v1/news/last_next/' + DetailsId).then(res=>{
        this.lastMessage = res.last;
        this.nextMessage = res.next;
      })
    }
  },
  mounted(){
    // this.link = window.location.href.split('#')[1];
    this.link = 'http://m.gupm.net/#' + window.location.href.split('#')[1];
    let query = this.$route.query;
    if(query.metting){
      this.IsshowTime = true;
    }else{
      this.IsshowTime = false;
    }
    let memberID = this.$route.query.memberID;
    if(memberID){
      this.getMember();
    }else{
      this.getNewsDetails();
      this.getLastAndNext();
    }
    setTimeout(() => {
      this.loading = false
    }, 500);
    // this.$nextTick(()=>{
    //   this.qrcode();
    // })
  },
}
</script>

<style lang="scss">
  .details{
    .detailsMessage{max-width: 960px;margin: 0 auto;margin-top:20px;
      .title{font-size: 30px;font-weight: bold;color: #333;word-break: break-all;}
      .publishtime{font-size: 12px;color: #333;}
      .shareUrl{display: flex;justify-content: space-between;align-items: center;
        .share{display:flex;align-items:center;cursor:pointer;position: relative;
          .shareImg{position: absolute;left: -20px;top: 20px;height: 94px;width: 94px;display: none;z-index: 1000;}
        }
        .share:hover{.shareImg{display: block;}}
      }
      .timeAndaddress{margin: 15px 0;display:flex;flex-wrap:wrap;
        .bg{background: #0080ff;border-top-left-radius: 4px;border-bottom-left-radius: 4px;color: #fff;border: 1px solid #0080ff;}
        .common{display: inline-block;padding: 4px 6px;font-size: 14px;}
        .text{color: #333;border: 1px solid #0080ff;border-top-right-radius: 4px;border-bottom-right-radius: 4px;margin-right: 15px;}
      }
      .content{padding-top: 15px;border-bottom:1px solid #ececec;border-top:1px solid #ececec;margin-top:8px;word-break:break-all;
        img{max-width: 100%;}
        video{max-width: 100%;}
      }
      .othernews{font-size: 12px;color: #646464;line-height: 50px;}
    }
  }
</style>